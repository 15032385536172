const plugin = (store) => {
    store.subscribeAction({
        before: (action, state) => {
            // called before every action.
            // The action comes in the format of `{ type, payload }`.
        },
        after: (action, state) => {
            // called before every action.
            // The action comes in the format of `{ type, payload }`.
        },
    });


    // called when the store is initialized
    store.subscribe((mutation, state) => {
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.


        if (mutation.type !== "setValue") {
            return;
        }

        if (mutation.payload.name !== "shipment[sale_id]" && mutation.payload.name !== "shipment[purchase_id]" && mutation.payload.name !== "shipment.sale_id"  && mutation.payload.name !== "shipment.purchase_id" ) {
            return
        }

        if (mutation.payload.name === "shipment[sale_id]" || mutation.payload.name === "shipment.sale_id" ) {
            // // if the user switched from an existing sale to a new one we remove the sale_attributes
            // // so that the server populates with new ones
            // if (mutation.payload.value === '' || mutation.payload.value === null) {
            //     store.commit('setValue', {
            //             value: {},
            //             name: 'shipment[sale_attributes]'
            //         }
            //     )
            // }
            //  short timeout in order to let vue js display the frame (via the v-if directive)
            setTimeout(function(){
                let frame = document.getElementById('sale_frame')
                if (frame) {
                    frame.src = "/admin/"+ AppInfo.legal_entity_slug + "/sales/" + mutation.payload.value
                }
            }, 100);
        }

        if (mutation.payload.name === "shipment[purchase_id]" || mutation.payload.name === "shipment.purchase_id" ) {
            // // if the user switched from an existing purchase to a new one we remove the shipment_attributes
            // // so that the server populates with new ones
            // if (mutation.payload.value === '' || mutation.payload.value === null) {
            //
            //     store.commit('setValue', {
            //             value: {},
            //             name: 'shipment[purchase_attributes]'
            //         }
            //     )
            // }

                //  short timeout in order to let vue js display the frame (via the v-if directive)
            setTimeout(function(){
                let frame = document.getElementById('purchase_frame')
                if (frame) {
                    frame.src = "/admin/"+ AppInfo.legal_entity_slug + "/purchases/" + mutation.payload.value
                }
            }, 100);
        }

    });
};

export default plugin;
