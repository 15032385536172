import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  replaceShipmentFrame() {
    const shipmentFrame = this.element.querySelector('turbo-frame#mark-to-market-details-by-shipments');

    if (shipmentFrame) {
      const detailsDiv = shipmentFrame.querySelector('div#mtm-shipments-details');

      if (detailsDiv) {
        shipmentFrame.src = '';
        shipmentFrame.innerHTML = '<p class="text-center text-chady-gray-800 text-lg">Live shipments for a ticket</p>';
      }
    }
  }
}
