import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['row']
  static values = {
    turboFrame: String,
  }
  redirect (event) {
    const row = event.currentTarget
    const url = row.getAttribute("data-url");

    if (url) {
      console.log(this.turboFrameValue)
      Turbo.visit(url, { frame: this.turboFrameValue })
    }
  }
}
