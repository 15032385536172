import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

ActiveStorage.start()

// Import controllers, pagy and channels
import "../controllers"
import "../pagy.js.erb"
import "../channels/index.js"

// Handle SCSS and CSS imports
import '../stylesheets/application.scss'


// Disabled automatically buttons when submit
document.addEventListener('turbo:submit-end', (event) => {
  if (event.detail.fetchResponse.response.redirected === true) {
    event.target.querySelectorAll('[type=submit]').forEach((button) => {
      button.disabled = true;
    });
  }
});
