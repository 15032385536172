import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['content']

  connect () {
    window.addEventListener('click', this.hideDropdowns.bind(this));
  }

  disconnect () {
    window.removeEventListener('click', this.hideDropdowns.bind(this));
  }

  toggle () {
    this.contentTarget.classList.toggle("legal-entity-dropdown--show");
  }

  hideDropdowns (event) {
    if (!event.target.matches('.legal-entity-dropdown--btn')) {
      if (this.contentTarget.classList.contains('legal-entity-dropdown--show')) {
        this.contentTarget.classList.remove('legal-entity-dropdown--show');
      }
    }
  }
}
