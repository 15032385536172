import { Controller } from '@hotwired/stimulus';

// Constant message to warn users when they attempt to leave the page.
const WARNING_MESSAGE = 'Are you sure you want to leave the page? If you leave this page, the data will not be saved.'

export default class extends Controller {
  // A flag to determine if we should skip showing the warning message.
  skipWarningFlag = false;

  // The `connect` method is called when the controller is connected to the DOM.
  connect () {
    // Binding class methods to the current instance, ensuring that 'this' always
    // refers to the controller instance when the methods are used as event handlers.
    this.boundHandleTurboWarnUser = this.handleTurboWarnUser.bind(this);
    this.boundHandleUnloadWarning = this.handleUnloadWarning.bind(this);

    // Adding event listeners:
    // - 'turbo:before-visit' is triggered before Turbo (a library for speeding up web navigation) navigates to a new page.
    // - 'beforeunload' is a standard browser event triggered before the window is closed or navigated away from.
    window.addEventListener('turbo:before-visit', this.boundHandleTurboWarnUser);
    window.addEventListener('beforeunload', this.boundHandleUnloadWarning);
  }

  // The `disconnect` method is called when the controller is removed from the DOM.
  disconnect () {
    // Removing the event listeners when the controller is disconnected ensures that we don't
    // end up with multiple listeners if the controller is repeatedly connected and disconnected.
    window.removeEventListener('turbo:before-visit', this.boundHandleTurboWarnUser);
    window.removeEventListener('beforeunload', this.boundHandleUnloadWarning);
  }

  // Method to programmatically skip the warning. For example, this can be used
  // if there's a button on the page that the user can click to confirm they want to navigate away.
  skipWarning () {
    this.skipWarningFlag = true;
  }

  // Event handler for the 'beforeunload' event. If the skipWarningFlag is set, it just resets the flag.
  // Otherwise, it prevents the default behavior and shows the warning message to the user.
  handleUnloadWarning (event) {
    if (this.skipWarningFlag) {
      this.skipWarningFlag = false;
      return;
    }

    event.preventDefault();
    event.returnValue = WARNING_MESSAGE;
  }

  // Event handler for the 'turbo:before-visit' event. Similar to `handleUnloadWarning`,
  // but uses a confirmation dialog to get user input.
  handleTurboWarnUser (event) {
    if (this.skipWarningFlag) {
      this.skipWarningFlag = false;
      return;
    }

    if (!window.confirm(WARNING_MESSAGE)) {
      event.preventDefault();
    }
  }
}
