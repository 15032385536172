import { Controller } from "stimulus";
import tippy from 'tippy.js';

export default class extends Controller {

    static values = { remotePath: String, id: String }

    initialize() {
        this.initPopup();
    }

    initPopup() {
        let turboFrameHtml = `<turbo-frame id="${this.idValue}" src="${this.remotePathValue}" loading="lazy"></turbo-frame>`;
        tippy(this.element, {
            content: turboFrameHtml,
            allowHTML: true,
            interactive: true, // this makes the tooltip interactive so you can select text or click links inside it
            theme: 'light-border',
            popperOptions: {
                strategy: 'fixed', // make sure the toolip is always displayd properly, even if attached to elements whose parents prevent overflow
            },
        });
    }
}
