import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['nodeElement', 'replaceNodeElement']

  connect () {
  }

  nodeElementTargetConnected (node) {
    if(this.hasReplaceNodeElementTarget) {
      const newNode = node.innerHTML
      this.replaceNodeElementTarget.innerHTML = newNode
    }
  }
}
