import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['notification', 'progress']
  static values = {
    delay: { type: Number, default: 5000 },
    autoClose: { type: Boolean, default: true }
  }

  connect () {
    if (this.autoCloseValue === false) return;
    this.initializeProgressBar();

    this.timeout = setTimeout(() => {
      this.dismiss()
    }, this.delayValue)
  }

  initializeProgressBar () {
    this.progressTarget.style.animationDuration = `${this.delayValue}ms`;
    this.progressTarget.classList.add('progress-bar-animation');
  }

  async dismiss () {
    this.notificationTarget.classList.add('notification-box--fade-out')

    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    setTimeout(() => {
      this.notificationTarget.remove()
    }, 500);
  }
}
