// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js or *_controller.ts.

import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"
import { registerBotyglotControllers } from 'elevate_ds_rails';

const application = Application.start()
const controllers = import.meta.globEager('./**/*_controller.js')

registerControllers(application, controllers)
registerBotyglotControllers(application);
