import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { delay: Number }

  initialize () {
    this.save = this.save.bind(this)
  }

  connect () {
    if (this.delayValue > 0) {
      this.save = this.debounce(this.save, this.delayValue)
    }
  }

  save () {
    this.element.requestSubmit()
  }


  debounce (func, wait) {
    let timeout;
    return function executedFunction (...args) {
      const context = this;

      const later = () => {
        timeout = null;
        func.apply(context, args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
}
