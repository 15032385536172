import { Controller } from '@hotwired/stimulus';

export default class extends Controller {


  static targets = ['details']

  connect () {
  }

  show (event) {
    if (this.hasDetailsTarget) {
      this.detailsTarget.classList.toggle('hidden')
      let iconElement = event.srcElement.childNodes[1]

      this.rotateArrow(iconElement)
    }
  }

  rotateArrow (element) {
    element.style.transition = 'transform 0.2s ease';

    if (element.style.transform === 'rotate(180deg)') {
      element.style.transform = 'rotate(0deg)';
    } else {
      element.style.transform = 'rotate(180deg)';
    }
  }
}
