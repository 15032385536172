import { Controller } from "@hotwired/stimulus";
import turboFrame from "@/forms/plugins/turbo-frame";

export default class extends Controller {
  static values = {
    turboFramesIds: Array // Contains the ids of the turbo-frames to be reloaded
  }

  updateAllTurboFrames (event) {
    if (event.detail.success && this.turboFramesIdsValue) {
      this.turboFramesIdsValue.forEach(nodeId => {
        const turboFrame = document.querySelector(`turbo-frame#${nodeId}`) // Get turbo-frame html element

        this.reloadFrame(turboFrame);
      })
    }
  }

  reloadFrame (turboFrameElement) {
    if (!turboFrameElement) return;

    if (turboFrameElement.src) {
      turboFrameElement.reload();
    }
  }
}
